import React, { useState } from "react"
import { formEncode } from '../utils/utils'
import tikka from "../../content/assets/img/tikka-small.png"
import Popup from "./popup";


function FeedbackForm(props){
    let [popUpVisible, setPopUpVisible] = useState(false);

    const[botForm, setBotForm] = useState({
        OverallSatisfaction: "",
        HowDidYouLearnAboutTheJob: "",
        HowDidYouLearnAboutTheJobOther: "",
        LinkedIn: "n/a",
        FirstContact: "n/a",
        FirstDiscussion: "n/a",
        CodeTestAndFeedback: "n/a",
        SecondDiscussion: "n/a",
        DuckPuzzle: "n/a",
        LevelOfInformationAboutTickingbotAndTheJob: "n/a",
        LevelOfCommunicationOfTheRecruitingProcess: "n/a",
        JobAd: "n/a",
        WebSite: "n/a",
        duunitoriapplying: "",
        duunitoriquestions: "",
        duunitoriwithoutquick: "",
        Comments: "",
        ApplyOtherPosition: "",
        NPS: "",
        Suggestions: "",
    });

    function handleChange(e){
        const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const fieldName = e.target.name;
        const newBotForm = {...botForm};
        newBotForm[fieldName] = newValue;
        // console.log(newBotForm);
        setBotForm(newBotForm);
    }

    function handleSubmit(e){
        e.preventDefault();
        // console.warn(e.target.name, botForm);
    
        const form = e.target;
        fetch('/', {
          method: 'POST',
          body: formEncode({
            'form-name': form.getAttribute('name'),
            ...botForm,
          }),
        })
          .then(() => {
            setPopUpVisible(true);
            //   navigate(form.getAttribute('action'))
            })
            .catch((error) => {
                // console.error(error);
            });
    }
    return(
<div className="bot-page__in bg-green-light">
        {
          popUpVisible && <Popup buttonText="Close" noClose={true} onClose={()=>setPopUpVisible(false)}>Your feedback has been sent, thank you! </Popup>
         }  
    <section className="bot-section-text container-800">
        <form method="post" name="feedbackForm" className="privacy-form-in" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="feedbackForm"/>
        <div className="tar">
        <img src={tikka} className="feedback-form-tikka" alt="tikka"></img>
        </div>
            <h2 className="bot-feedback-form-title">Tickingbot loves to know what You think
    </h2>
    <div className="privacy-form-section mb10">
        <fieldset>
                <legend>1. On scale 1-5, how satisfied are you with our recruiting process in overall? (1 = Not at all satisfied, 5 = extremely satisfied)
    
                </legend>
        <div className="privacy-form-horizontal-list">
            <label className="bot-radio">
                <input type="radio" id="radio-1" name="OverallSatisfaction" value="1" checked={botForm.OverallSatisfaction==="1"} onChange={handleChange} required></input>
                <span>1</span>
            </label>
            <label className="bot-radio">
            <input type="radio" id="radio-2" name="OverallSatisfaction" value="2" checked={botForm.OverallSatisfaction==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="radio-3" name="OverallSatisfaction" value="3" checked={botForm.OverallSatisfaction==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="radio-4" name="OverallSatisfaction" value="4" checked={botForm.OverallSatisfaction==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="radio-5" name="OverallSatisfaction" value="5" checked={botForm.OverallSatisfaction==="5"} onChange={handleChange}></input>
                <span>5</span></label>
        </div>    
        </fieldset>
    </div>
    <div className="privacy-form-section mb10">
        <fieldset>
            <legend>2. How did you learn about the job opening?</legend>
            <div className="privacy-form-horizontal-list privacy-form-horizontal-list--table">
                <div className="privacy-form-horizontal-list-in">
                <label className="bot-radio">
                <input type="radio" id="jobs" name="HowDidYouLearnAboutTheJob" value="jobs" checked={botForm.HowDidYouLearnAboutTheJob==="jobs"} onChange={handleChange} required></input>
                <span>Tickingbot.fi/jobs</span></label>
                <label className="bot-radio">
                <input type="radio" id="linkedin" name="HowDidYouLearnAboutTheJob" value="linkedin" checked={botForm.HowDidYouLearnAboutTheJob==="linkedin"} onChange={handleChange}></input>
                <span>Tickingbot LinkedIn Ad</span></label>
                <label className="bot-radio">
                <input type="radio" id="te" name="HowDidYouLearnAboutTheJob" value="TE" checked={botForm.HowDidYouLearnAboutTheJob==="TE"} onChange={handleChange}></input>
                <span>TE-palvelut.fi</span></label>
                <label className="bot-radio">
                <input type="radio" id="market" name="HowDidYouLearnAboutTheJob" value="Duunitori" checked={botForm.HowDidYouLearnAboutTheJob==="Duunitori"} onChange={handleChange}></input>
                <span>Duunitori.fi</span></label>
                <label className="bot-radio">
                <input type="radio" id="market" name="HowDidYouLearnAboutTheJob" value="AcademicWork" checked={botForm.HowDidYouLearnAboutTheJob==="AcademicWork"} onChange={handleChange}></input>
                <span>Academic Work</span></label>
                <label className="bot-radio">
                <input type="radio" id="friend" name="HowDidYouLearnAboutTheJob" value="friend" checked={botForm.HowDidYouLearnAboutTheJob==="friend"} onChange={handleChange}></input>
                <span>From a friend</span></label>
                <label className="bot-radio">
                <input type="radio" id="headhunter" name="HowDidYouLearnAboutTheJob" value="headhunter" checked={botForm.HowDidYouLearnAboutTheJob==="headhunter"} onChange={handleChange}></input>
                <span>From a headhunter or direct sourcing</span></label>
                <label className="bot-radio">
                <input type="radio" id="other" name="HowDidYouLearnAboutTheJob" value="other" checked={botForm.HowDidYouLearnAboutTheJob==="other"} onChange={handleChange}></input>
                <span>Other (please specify)</span></label> 
                </div>   
            <div className="privacy-form-input">
                <input type="text" id="howdidyoulearnOther" placeholder=" " name="HowDidYouLearnAboutTheJobOther" value={botForm.HowDidYouLearnAboutTheJobOther} onChange={handleChange}></input>
            </div>
            </div>
        </fieldset>
    </div>
    <div className="privacy-form-section mb10">
            <div>3. On same scale 1-5, let us know how satisfied are you with different phases of the recruiting process? (1 = Not at all satisfied, 5 = extremely satisfied) 
    Select N/A, if not relevant.
    </div>
         <br></br>
        <fieldset>
            <legend>Job Ad</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="r-1" name="JobAd" value="1" checked={botForm.JobAd==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="r-2" name="JobAd" value="2" checked={botForm.JobAd==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="r-3" name="JobAd" value="3" checked={botForm.JobAd==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="r-4" name="JobAd" value="4" checked={botForm.JobAd==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="r-5" name="JobAd" value="5" checked={botForm.JobAd==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="na" name="JobAd" value="n/a" checked={botForm.JobAd==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>First contact (Tickingbot recruiter or a head hunter)</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasesfirstcontact1" name="FirstContact" value="1" checked={botForm.FirstContact==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesfirstcontact2" name="FirstContact" value="2" checked={botForm.FirstContact==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesfirstcontact3" name="FirstContact" value="3" checked={botForm.FirstContact==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesfirstcontact4" name="FirstContact" value="4" checked={botForm.FirstContact==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesfirstcontact5" name="FirstContact" value="5" checked={botForm.FirstContact==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesfirstcontactna" name="FirstContact" value="n/a" checked={botForm.FirstContact==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>First discussion</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphone1" name="FirstDiscussion" value="1" checked={botForm.FirstDiscussion==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphone2" name="FirstDiscussion" value="2" checked={botForm.FirstDiscussion==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphone3" name="FirstDiscussion" value="3" checked={botForm.FirstDiscussion==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphone4" name="FirstDiscussion" value="4" checked={botForm.FirstDiscussion==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphone5" name="FirstDiscussion" value="5" checked={botForm.FirstDiscussion==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphonena" name="FirstDiscussion" value="n/a" checked={botForm.FirstDiscussion==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>Code test or design task and feedback</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasestest1" name="CodeTestAndFeedback" value="1" checked={botForm.CodeTestAndFeedback==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasestest2" name="CodeTestAndFeedback" value="2" checked={botForm.CodeTestAndFeedback==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasestest3" name="CodeTestAndFeedback" value="3" checked={botForm.CodeTestAndFeedback==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasestest4" name="CodeTestAndFeedback" value="4" checked={botForm.CodeTestAndFeedback==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasestest5" name="CodeTestAndFeedback" value="5" checked={botForm.CodeTestAndFeedback==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasestestna" name="CodeTestAndFeedback" value="n/a" checked={botForm.CodeTestAndFeedback==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>Second discussion</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasesinterview1" name="SecondDiscussion" value="1" checked={botForm.SecondDiscussion==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesinterview2" name="SecondDiscussion" value="2" checked={botForm.SecondDiscussion==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesinterview3" name="SecondDiscussion" value="3" checked={botForm.SecondDiscussion==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesinterview4" name="SecondDiscussion" value="4" checked={botForm.SecondDiscussion==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesinterview5" name="SecondDiscussion" value="5" checked={botForm.SecondDiscussion==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesinterviewna" name="SecondDiscussion" value="n/a" checked={botForm.SecondDiscussion==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>Duck puzzle (in the second discussion)</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasespuzzle1" name="DuckPuzzle" value="1" checked={botForm.DuckPuzzle==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasespuzzle2" name="DuckPuzzle" value="2" checked={botForm.DuckPuzzle==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasespuzzle3" name="DuckPuzzle" value="3" checked={botForm.DuckPuzzle==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasespuzzle4" name="DuckPuzzle" value="4" checked={botForm.DuckPuzzle==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasespuzzle5" name="DuckPuzzle" value="5" checked={botForm.DuckPuzzle==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasespuzzlena" name="DuckPuzzle" value="n/a" checked={botForm.DuckPuzzle==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>Level of information about Tickingbot and the job you applied</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasesabout1" name="LevelOfInformationAboutTickingbotAndTheJob" value="1" checked={botForm.LevelOfInformationAboutTickingbotAndTheJob==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesabout2" name="LevelOfInformationAboutTickingbotAndTheJob" value="2" checked={botForm.LevelOfInformationAboutTickingbotAndTheJob==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesabout3" name="LevelOfInformationAboutTickingbotAndTheJob" value="3" checked={botForm.LevelOfInformationAboutTickingbotAndTheJob==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesabout4" name="LevelOfInformationAboutTickingbotAndTheJob" value="4" checked={botForm.LevelOfInformationAboutTickingbotAndTheJob==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesabout5" name="LevelOfInformationAboutTickingbotAndTheJob" value="5" checked={botForm.LevelOfInformationAboutTickingbotAndTheJob==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesaboutna" name="LevelOfInformationAboutTickingbotAndTheJob" value="n/a" checked={botForm.LevelOfInformationAboutTickingbotAndTheJob==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>Level of communication in different phases of the recruiting process</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasesprocess1" name="LevelOfCommunicationOfTheRecruitingProcess" value="1" checked={botForm.LevelOfCommunicationOfTheRecruitingProcess==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesprocess2" name="LevelOfCommunicationOfTheRecruitingProcess" value="2" checked={botForm.LevelOfCommunicationOfTheRecruitingProcess==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesprocess3" name="LevelOfCommunicationOfTheRecruitingProcess" value="3" checked={botForm.LevelOfCommunicationOfTheRecruitingProcess==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesprocess4" name="LevelOfCommunicationOfTheRecruitingProcess" value="4" checked={botForm.LevelOfCommunicationOfTheRecruitingProcess==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesprocess5" name="LevelOfCommunicationOfTheRecruitingProcess" value="5" checked={botForm.LevelOfCommunicationOfTheRecruitingProcess==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesprocessna" name="LevelOfCommunicationOfTheRecruitingProcess" value="n/a" checked={botForm.LevelOfCommunicationOfTheRecruitingProcess==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>Tickingbot.fi website</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="website1" name="WebSite" value="1" checked={botForm.WebSite==="1"} onChange={handleChange} required="requared"></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="website2" name="WebSite" value="2" checked={botForm.WebSite==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="website3" name="WebSite" value="3" checked={botForm.WebSite==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="website4" name="WebSite" value="4" checked={botForm.WebSite==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="website5" name="WebSite" value="5" checked={botForm.WebSite==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="websitena" name="WebSite" value="n/a" checked={botForm.WebSite==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend><br/>Tickingbot in LinkedIn</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="differentphasesin1" name="LinkedIn" value="1" checked={botForm.LinkedIn==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesin2" name="LinkedIn" value="2" checked={botForm.LinkedIn==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesin3" name="LinkedIn" value="3" checked={botForm.LinkedIn==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesin4" name="LinkedIn" value="4" checked={botForm.LinkedIn==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesin5" name="LinkedIn" value="5" checked={botForm.LinkedIn==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesinna" name="LinkedIn" value="n/a" checked={botForm.LinkedIn==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
    </div>
    <div className="privacy-form-section mb40">
            <textarea name="Comments" id="comments" rows="5" cols="40" wrap="hard" placeholder="Open comments on the phases you have assessed above. You can also answer in Finnish." value={botForm.Comments} onChange={handleChange}></textarea>
    </div>
    {/*     
    <div className="privacy-form-section mb10">
            <div>4. If you applied with Duunitori, please answer also these
            </div>
         <br></br>
        <fieldset>
            <legend>How satisfied were you with the applying without a CV?</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplying1" name="duunitoriapplying" value="1" checked={botForm.duunitoriapplying==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplying2" name="duunitoriapplying" value="2" checked={botForm.duunitoriapplying==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplying3" name="duunitoriapplying" value="3" checked={botForm.duunitoriapplying==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplying4" name="duunitoriapplying" value="4" checked={botForm.duunitoriapplying==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplying5" name="duunitoriapplying" value="5" checked={botForm.duunitoriapplying==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplying6" name="duunitoriapplying" value="6" checked={botForm.duunitoriapplying==="6"} onChange={handleChange}></input>
                <span>6</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplying7" name="duunitoriapplying" value="7" checked={botForm.duunitoriapplying==="7"} onChange={handleChange}></input>
                <span>7</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriapplyingna" name="duunitoriapplying" value="n/a" checked={botForm.duunitoriapplying==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend>How relevant were the multiple choice questions for the position?</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestions1" name="duunitoriquestions" value="1" checked={botForm.duunitoriquestions==="1"} onChange={handleChange} required></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestions2" name="duunitoriquestions" value="2" checked={botForm.duunitoriquestions==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestions3" name="duunitoriquestions" value="3" checked={botForm.duunitoriquestions==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestions4" name="duunitoriquestions" value="4" checked={botForm.duunitoriquestions==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestions5" name="duunitoriquestions" value="5" checked={botForm.duunitoriquestions==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestions6" name="duunitoriquestions" value="6" checked={botForm.duunitoriquestions==="6"} onChange={handleChange}></input>
                <span>6</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestions7" name="duunitoriquestions" value="7" checked={botForm.duunitoriquestions==="7"} onChange={handleChange}></input>
                <span>7</span></label>
            <label className="bot-radio">
            <input type="radio" id="duunitoriquestionsna" name="duunitoriquestions" value="n/a" checked={botForm.duunitoriquestions==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
        <fieldset>
            <legend>Would you have applied this position without the quick apply questionnaire?</legend>
            <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="duunitoriwithoutquickyes" name="duunitoriwithoutquick" value="Yes" checked={botForm.duunitoriwithoutquick==="Yes"} onChange={handleChange} required></input>
                <span>Yes</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphone2" name="duunitoriwithoutquick" value="No" checked={botForm.duunitoriwithoutquick==="No"} onChange={handleChange}></input>
                <span>No</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphone3" name="duunitoriwithoutquick" value="Maybe" checked={botForm.duunitoriwithoutquick==="Maybe"} onChange={handleChange}></input>
                <span>Maybe</span></label>
            <label className="bot-radio">
            <input type="radio" id="differentphasesoverphonena" name="duunitoriwithoutquick" value="n/a" checked={botForm.duunitoriwithoutquick==="n/a"} onChange={handleChange}></input>
                <span>N/A</span></label>
        </div>
        </fieldset>
    </div>
    */}
        <div className="privacy-form-section mb10">
        <fieldset>
                <legend>4. If you were not chosen, would you consider applying for another position at Tickingbot later?</legend>
        <div className="privacy-form-horizontal-list ">
            <label className="bot-radio">
            <input type="radio" id="otherpositionyes" name="ApplyOtherPosition" value="yes" checked={botForm.ApplyOtherPosition==="yes"} onChange={handleChange}></input>
            <span>Yes</span></label>
            <label className="bot-radio">
            <input type="radio" id="otherpositionno" name="ApplyOtherPosition" value="no" checked={botForm.ApplyOtherPosition==="no"} onChange={handleChange}></input>
            <span>No</span></label>
            <label className="bot-radio">
            <input type="radio" id="otherpositionnot" name="ApplyOtherPosition" value="NoAnswer" checked={botForm.ApplyOtherPosition==="NoAnswer"} onChange={handleChange}></input>
            <span>No answer</span></label>
        </div>     
        </fieldset>
        </div>
        <div className="privacy-form-section mb10">
        <fieldset>
                <legend>5. Based on your experience from Tickingbot recruiting process, how likely is it that you would recommend Tickingbot as a place to work? Not at all likely 0 – 10 Extremely likely</legend>
        <div className="privacy-form-horizontal-list df">
            <label className="bot-radio">
            <input type="radio" id="yourexperience0" name="NPS" value="0" checked={botForm.NPS==="0"} onChange={handleChange} required></input>
                <span>0</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience1" name="NPS" value="1" checked={botForm.NPS==="1"} onChange={handleChange}></input>
                <span>1</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience2" name="NPS" value="2" checked={botForm.NPS==="2"} onChange={handleChange}></input>
                <span>2</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience3" name="NPS" value="3" checked={botForm.NPS==="3"} onChange={handleChange}></input>
                <span>3</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience4" name="NPS" value="4" checked={botForm.NPS==="4"} onChange={handleChange}></input>
                <span>4</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience5" name="NPS" value="5" checked={botForm.NPS==="5"} onChange={handleChange}></input>
                <span>5</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience6" name="NPS" value="6" checked={botForm.NPS==="6"} onChange={handleChange}></input>
                <span>6</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience7" name="NPS" value="7" checked={botForm.NPS==="7"} onChange={handleChange}></input>
                <span>7</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience8" name="NPS" value="8" checked={botForm.NPS==="8"} onChange={handleChange}></input>
                <span>8</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience9" name="NPS"value="9" checked={botForm.NPS==="9"} onChange={handleChange}></input>
                <span>9</span></label>
            <label className="bot-radio">
            <input type="radio" id="yourexperience10" name="NPS" value="10" checked={botForm.NPS==="10"} onChange={handleChange}></input>
                <span>10</span></label>
        </div>    
        </fieldset>
        </div>
    <div className="privacy-form-section mb40">
    <legend className="privacy-form-title--message">
        6. Suggestions to improve the recruiting process at our company? You can also answer in Finnish.
    </legend>
            <textarea name="Suggestions" id="suggestions" className="mb2" rows="5" cols="40" wrap="hard" placeholder="" value={botForm.Suggestions} onChange={handleChange}></textarea>
        </div>
        <button className="bot-form-btn bot-form-btn_big mb40" type="submit">Send Feedback</button>
        <div className="color-brown">Follow us in <a href={props.settings.linkedin} target="_blank" className="bot-linkedin" rel="noreferrer">LinkedIn!</a></div>
        </form>
    </section>
</div>
    )
}

export default FeedbackForm