import React from "react"
import { graphql } from 'gatsby'
import FeedbackForm from "../components/feedback-form"
import Footer from "../components/footer"
import SEO from "../components/seo"
import TopMenu from "../components/topmenu"
import CookiesPopup from "../components/cookies-popup"


const FeedbackPageTemplate = ({ data, pageContext }) => {
  const slug = data.page.fields.slug;

  return (
    <section className="bot-page">
      <SEO
        title="Home"
        keywords={[`Embedded`, `Sulautetut`, `sulautettu`, `Tickingbot`, `software`, `consultant`, `ohjelmistokonsultti`, `Test automation`, `testiautomaatio`, `testausautomaatio`]} 
        />
      <TopMenu 
      language={pageContext.language} 
      languages={pageContext.languages} 
      slug={slug} 
      translation={pageContext.translation}
      jobs={data.index.frontmatter.jobs}
      listJobs={data.jobs.nodes}
      services={data.index.frontmatter.services.list}
      settings={data.settings.childMdx.frontmatter}
      ></TopMenu>
      <FeedbackForm settings={data.settings.childMdx.frontmatter}></FeedbackForm>
      <Footer settings={data.settings.childMdx.frontmatter}></Footer>
      <CookiesPopup translation={pageContext.translation}></CookiesPopup>
    </section>
  )
}

export const indexQuery = graphql`
  query pageById($id: String!, $language: String!) {
    page: mdx(
      id: { eq: $id }
    ) {
      fields {
        slug
      }
      frontmatter {
        language
        title
        description
      }
    }
    jobs: allMdx(filter: {frontmatter: {contentType: {eq: "job"}, language: {eq: $language}}}) {
      nodes {
        fields{
          slug
        }
        frontmatter {
          title
          slug
          enabled
        }
      }
    }
    index: mdx(frontmatter: {templateKey: {eq: "templates/index"}, language: {eq: $language}}) {
      frontmatter {
        services {
          list: services {
            title
          }
        }
        jobs {
          list{
            position
          }
        }
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          companyname
          sitename
          address
          address2
          phone
          linkedin
          email
        }
      }
    }
  }
`

export default FeedbackPageTemplate

